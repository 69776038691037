<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary">Mymovingway</h2>
        </b-link>

        <!-- <b-card-title class="mb-1 text-center" v-if="$i18n.locale === 'tr'">
          {{ companyBranchName }} Firmasına Müşteri Olarak Katıl
        </b-card-title>
         <b-card-title class="mb-1 text-center" v-if="$i18n.locale === 'en'">
          Join {{ companyBranchName }} As A Customer
        </b-card-title> -->
        <b-card-title class="mb-1 text-center">
          Müşteri Olarak Katıl
        </b-card-title>

        <b-row class="center profile-edit-buttons">
          <b-media class="mb-2 css-reset">
            <b-img
                ref="previewEl"
                :src="userImg"
                class="profile-img my-2"
                center
            />
          </b-media>
        </b-row>
        <b-row class="center profile-edit-buttons">
          <div class="d-flex flex-wrap">
            <b-button variant="primary upload-button" @click="toggleShow">
              <span class="d-none d-sm-inline">
                <b-icon-capslock-fill class="up-icon" />
                <span class="up-text">{{ $t("Upload") }}</span>
              </span>
              <b-icon-capslock-fill class="up-icon d-inline d-sm-none" />
            </b-button>
            <my-upload
              field="img"
              @crop-success="cropSuccess"
              v-model="show"
              :width="150"
              :height="150"
              langType="tr"
              :params="params"
              :headers="headers"
              img-format="png"
            ></my-upload>
            <b-button
              variant="danger remove-button"
              class="ml-1"
              @click="removePhoto"
            >
              <span class="d-none d-sm-inline">
                <b-icon-trash class="remove-icon" />
                <span class="remove-text">{{ $t("Remove") }}</span>
              </span>
              <b-icon-trash class="remove-icon d-inline d-sm-none" />
            </b-button>
          </div>
        </b-row>

        <b-form class="auth-login-form mt-2" @submit.prevent>
          <b-form-group :label="$t('Name')" label-for="first-name">
            <b-input-group>
              <b-form-input
                @blur="$v.instructor_data.name.$touch()"
                :class="{
                  'is-invalid': $v.instructor_data.name.$error,
                }"
                id="first-name"
                type="text"
                :placeholder="$t('Name')"
                v-model="instructor_data.name"
              />
              <small
                v-if="isFinal && !$v.instructor_data.name.required"
                class="form-text text-danger"
                >{{ $t("The name field is required") }}</small
              >
            </b-input-group>
          </b-form-group>

          <b-form-group :label="$t('Surname')" label-for="last-name">
            <b-input-group>
              <b-form-input
                @blur="$v.instructor_data.last_name.$touch()"
                :class="{
                  'is-invalid': $v.instructor_data.last_name.$error,
                }"
                id="last-name"
                type="text"
                :placeholder="$t('Surname')"
                v-model="instructor_data.last_name"
              />
              <small
                v-if="isFinal && !$v.instructor_data.last_name.required"
                class="form-text text-danger"
                >{{ $t("The surname field is required") }}</small
              >
            </b-input-group>
          </b-form-group>
          <b-form-group :label="$t('Identity')" label-for="Identity">
            <b-input-group>
              <b-form-input
                @blur="$v.instructor_data.identification_number.$touch()"
                :class="{'is-invalid': $v.instructor_data.identification_number.$error,}"
                id="identity"
                type="number"
                :placeholder="$t('Identity')"
                v-model="instructor_data.identification_number"
              />
                <small v-if="isFinal && !$v.instructor_data.identification_number.required" class="form-text text-danger" > {{$t("ID number required")}}</small>
              <small
                v-if="isFinal && !$v.instructor_data.identification_number.numeric"
                class="form-text text-danger"
              >
                {{ $t("ID number must consist of numbers") }}</small
              >
              <small
                v-if="!$v.instructor_data.identification_number.maxLength
                "
                class="form-text text-danger"
                >{{ $t("ID number length must be 11 digits") }}</small
              >
              <small
                v-if="
                  !$v.instructor_data.identification_number.minLength
                "
                class="form-text text-danger"
                >{{ $t("ID number length must be 11 digits") }}</small
              >
            </b-input-group>
          </b-form-group>
          <!-- <b-form-group
            :label="$t('Birthday')"
            label-for="birthday"
            class="mb-1">
            <b-input-group class="mb-0">
              <flat-pickr
                @blur="$v.instructor_data.birthday.$touch()"
                :placeholder="$t('Birthday')"
                v-model="instructor_data.birthday"
                class="form-control"
                :config="config"/>
            </b-input-group>
            <small
              v-if="isFinal && !$v.instructor_data.birthday.required"
              class="form-text text-danger"
              >{{ $t("The birthday field is required") }}</small>
          </b-form-group> -->

          <div>
            <label for="example-input">{{ $t("Gender") }}</label>
          </div>
          <div class="mb-1">
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="md"
                :variant="maleColor"
                @click="getGender(1)"
              >
                {{ $t("Male") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="femaleColor"
                size="md"
                @click="getGender(0)"
              >
                {{ $t("Female") }}
              </b-button>
            </b-button-group>
            <small
              v-if="isFinal && !$v.instructor_data.gender.numeric"
              class="form-text text-danger"
            >
              {{ $t("Gender required") }}</small
            >
          </div>

          <b-form-group :label="$t('E-Mail')" label-for="email">
            <b-form-input
              @blur="$v.instructor_data.email.$touch()"
              :class="{ 'is-invalid': $v.instructor_data.email.$error }"
              id="email"
              v-model="instructor_data.email"
              type="email"
              :placeholder="$t('Email')"
            />
            <small
              v-if="isFinal && !$v.instructor_data.email.required"
              class="form-text text-danger"
              >{{ $t("This field is required") }}</small
            >
            <small
              v-if="isFinal && !$v.instructor_data.email.email"
              class="form-text text-danger"
              >{{ $t("The email field must be a valid email") }}</small
            >
          </b-form-group>

          <b-form-group :label="$t('Phone')" label-for="phone">
            <b-input-group>
              <b-form-input
                @blur="$v.instructor_data.phone_number.$touch()"
                :class="{
                  'is-invalid': $v.instructor_data.phone_number.$error,
                }"
                type="number"
                id="phone"
                v-model="instructor_data.phone_number"
                :placeholder="$t('Phone')"/>
            </b-input-group>
            <small
              v-if="isFinal && !$v.instructor_data.phone_number.required"
              class="form-text text-danger">
              {{ $t("The phone number field format is invalid") }}</small>
            <small
              v-if="isFinal && !$v.instructor_data.phone_number.numeric"
              class="form-text text-danger"
            >
              {{ $t("The phone number field format is invalid") }}</small
            >
            <small
              v-if="isFinal && !$v.instructor_data.phone_number.maxLength"
              class="form-text text-danger"
              >{{ $t("The phone number field format is invalid") }}</small
            >
          </b-form-group>
                  <b-form-group :label="$t('City')" label-for="city">
                        <v-select
                            style="width: 100%; z-index: 450; display: inline-block"
                            :reduce="x => x.city_id"
                            v-model="selectedCity"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="cities"
                            value="city_id"
                            label="city_name"
                            name="city"
                            id="city"
                            @blur="$v.instructor_data.city_id.$touch()"
                        ></v-select>
                        <small v-if="isFinal&& !$v.instructor_data.city_id.required" class="form-text text-danger"
                        >{{ $t('This field is required') }}</small>
                      </b-form-group>
                      <b-form-group :label="$t('Town')" label-for="town">
                      <v-select
                          style="width: 100%; z-index: 400; display: inline-block"
                          :reduce="x => x.town_id"
                          v-model="selectedTown"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="towns"
                          value="town_id"
                          label="town_name"
                          name="town"
                          @blur="$v.instructor_data.town_id.$touch()"
                      >
                      </v-select>
                      <small v-if="isFinal && !$v.instructor_data.town_id.required" class="form-text text-danger"
                      >{{ $t('This field is required') }}</small>
                    </b-form-group>


          <b-form-group :label="$t('Address')" label-for="address">
            <b-input-group>
              <b-form-textarea
                id="address"
                rows="3"
                v-model="instructor_data.address"
              />
            </b-input-group>
          </b-form-group>
          <div class="mt-1">
            <label
              for="acceptContract"
              class="mb-0 text-dark"
              style="display: flex; align-items: center"
            >
              <b-form-checkbox
                style="display: inline-flex"
                size="30"
                v-model="checked"
              ></b-form-checkbox
              ><a
                :href="`${apiURL}Files/CerezPolitikasi.pdf`"
                target="_blank"
                class="contractButton"
              >
                Sözleşmeyi
              </a>
              <span style="margin-left: 3px;"
                >okudum ve onaylıyorum.</span
              ></label
            >
            <small
              v-if="isFinal && !$v.checked.check"
              class="form-text text-danger"
            >
              {{ $t("Sözleşmeyi kabul etmeniz gerekmektedir.") }}
            </small>
          </div>

          <!-- submit button -->
          <b-button
            type="submit"
            class="mt-1 shadow update-button"
            @click="validationForm()"
            size="md"
            variant="outline-success"
            block
          >
            {{ $t("Register") }}
          </b-button>
        </b-form>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BButtonGroup,
  BCard,
  BCardGroup,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BRow,
  BVToastPlugin,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Ripple from 'vue-ripple-directive'
import { email, maxLength, minLength, numeric, required, } from 'vuelidate/lib/validators'
import flatPickr from 'vue-flatpickr-component'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import myUpload from 'vue-image-crop-upload/upload-2.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormInvalidFeedback,
    BBreadcrumb,
    BBreadcrumbItem,
    BVToastPlugin,
    BButtonGroup,
    BFormDatepicker,
    BAvatar,
    BRow,
    BImg,
    BCol,
    BCardGroup,
    vSelect,
    ToastificationContent,
    flatPickr,
    "my-upload": myUpload,
    BMedia,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      checked: false,
      apiURL: "",
      userEmail: "",
      password: "",
      status: "",
      // validation rules

      placeholder: "dd.mm.YYYY",
      required,
      companyBranchId: "",
      companyBranchName: "",
      instructor_data: {
        person_id: "",
        email: "",
        name: "",
        last_name: "",
        gender: "-",
        phone_number: "",
        city_id:"",
        town_id:"",
        birthday: "",
        kvkCheck: false,
        identification_number: null,
      },
      dataloaded: false,
      selectedCity: {
        //city_id:"00000000-0000-0000-0000-000000000000",
        city_name: this.$t("Please choose"),
      },
      selectedTown: {
        //town_id:"00000000-0000-0000-0000-000000000000",
        town_name: this.$t("Please choose"),
      },
      cities: [],
      towns: [],
      config: {
        dateFormat: "d.m.Y",
        locale: this.$i18n.locale === "en" ? "" : Turkish,
        maxDate: "12.12.2009",
        minDate: "01.01.1940",
      },
      maleColor: "outline-primary",
      femaleColor: "outline-primary",
      updateInsertType: 0,
      isNext: false,
      isFinal: false,
      isVerify: false,

      show: false,
      params: {
        token: "123456798",
        name: "avatar",
      },
      headers: {
        smail: "*_~",
      },
      userImg: "",

      filesrc: [
        {
          title: "my.pdf",
          src: "../assets/downloads/my.pdf",
        },
      ],
    };
  },
  directives: {
    Ripple,
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  watch: {
    selectedCity(val)
     {
      if(this.dataloaded===true)
      {
        this.selectedTown = {};
        this.instructor_data.city_id = val;
        this.towns = [];
        this.$database.DistributorService.get_towns_by_city_id(
          val
        ).then((res) => {
          if (!res.IsSuccess == true)
              return;
               res.Result.map((el) => {       
                let town={};
                  town.city_id =  el.CityId
                  town.town_id = el.TownId,
                  town.town_name = el.TownName        
                this.towns.push(town)
            });
              this.selectedTown.town_name="Lütfen Seçim Yapınız";
        });
      }
    },
    selectedTown(val) {
      this.instructor_data.town_id = val;
    },
  },
  methods: {
    validationForm() {
      this.isFinal = true;
      if (this.$v.$invalid) 
              return;

          this.$database.CustomerService.ExternalCustomerCreate({
        CompanyBranchId: this.companyBranchId,
        FirstName: this.instructor_data.name,
        LastName: this.instructor_data.last_name,
        IdentificationNumber: this.instructor_data.identification_number,
        Email: this.instructor_data.email.toLowerCase(),
        PhoneNumber: this.instructor_data.phone_number,
        Birthday: this.$functions.ConvertToEnglishDateFormat("01.01.2000"), //(this.instructor_data.birthday),
        Gender: this.instructor_data.gender,
        CountryId:"cf5e828c-e671-49b1-a747-13743f6ff13d",
        CityId:this.instructor_data.city_id,//"605a84c4-68f5-e911-a2d3-000c293978ed",
        TownId: this.instructor_data.town_id,//"814c96ed-6bf5-e911-a2d3-000c293978ed",
        Address: this.instructor_data.address,
        UserImg:this.userImg //şu anlık
      }).then((res) => {
        if (res.IsSuccess === true || res.IsSuccess === 1) {
          this.$functions.Messages.success("Customer Successfully Identified");
          this.$router.push({ name: "auth-login" });
        } else {
          this.$functions.Messages.error(res.Result);
        }
      });
    },
    getGender(val) {
      this.instructor_data.gender = val;
      if (val == 0) {
        this.femaleColor = "primary";
        this.maleColor = "outline-secondary";
      } else if (val == 1) {
        this.maleColor = "primary";
        this.femaleColor = "outline-secondary";
      }
    },
    removePhoto() {
      this.userImg =
        "https://img.icons8.com/cute-clipart/452/gender-neutral-user.png";
    },
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(userImg, field) {
      this.userImg = userImg;
      console.log(this.userImg);
    },
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
    onContext(ctx) {
      this.formatted = ctx.selectedFormatted;
      this.selected = ctx.selectedYMD;
    },
    getCities() {
      this.$database.DistributorService.get_cities().then((res) => {
        if (!res.IsSuccess == true)
              return;



         res.Result.map((el) => {       
                let city={};
                  city.country_id = el.CountryId
                  city.city_id =  el.CityId
                  city.city_name = el.CityName 
          
                this.cities.push(city)
            });

            // this.cities = res.result;
             // this.studio_data.city_id=res.result[0].city_id
               this.dataloaded=true;
            //  this.getTowns();
      });
    },


  },
  created() {
    this.apiURL = process.env.VUE_APP_API //"http://192.168.1.62:8001" //
    //this.companyBranchId = this.$route.params.companyBranchId
    this.companyBranchId = this.$route.query.param1
    // this.$route.query.param2 != undefined ? (this.companyBranchName = this.$route.query.param2) : (this.companyBranchName = '')
    this.userImg =
        'https://img.icons8.com/cute-clipart/452/gender-neutral-user.png'
    // this.companyBranchName = this.companyBranchName;
     this.getCities();
  },
  validations: {
    instructor_data: {
      name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone_number: {
        required,
        numeric: numeric,
        maxLength: maxLength(20),
      },
      city_id: {
        required:required
      },
      town_id:{
        required:required  
      },
      // birthday: {
      // required: required,
      // },
      identification_number: {
        required,
        numeric: numeric,
        maxLength: maxLength(11),
        minLength: minLength(11),
      },
      gender: {
        numeric: numeric,
      },
    },
    checked: {
      check: (value) => value === true,
    },
  },
};
</script>

<!-- stillendirme -->
<style scoped src="@/styles/css/customerPublicInvite.css"></style>
<style lang="scss" src="@/styles/scss/customerPublicInvite.scss"></style>
